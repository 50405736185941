  .main {
    width: 100%;
  }
  
  .section {
    margin-bottom: 32px;
  }
  
  .form {
    width: 100%;
    min-width: 400px;     
    max-width: 500px;     
    padding: 24px;
  }
  
  .row {
    margin-bottom: 24px;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .cell {
    width: 100%;
    
    > div {
      margin-bottom: 8px;
    }
  }
  
  .error {
    color: var(--error-color);
    margin: 16px 0;
    
    span {
      color: inherit;
    }
  }
  
  .footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid var(--border-color);
  }
  
  .buttons {
    display: flex;
    gap: 16px;
  }
  
 