@import './fonts';
@import './reset';
@import './variables';
@import './grid';
@import './flex';

.wrapper {
  background: linear-gradient(
    to left,
    $blank 0 50%,
    $geryer-background 50% 100%
  );
}

/* Main Layout */
.main-layout {
  display: grid;
  grid-template:
    "header header"
    "navigation content";
  grid-template-columns: 215px calc(100% - 215px);
  grid-template-rows: 64px Max(calc(100% - 64px), calc(100vh - 64px));
  min-width: 1336px;
  min-height: 100vh;
  height: 100%;
  &.impersonate {
    grid-template-rows: 124px Max(calc(100% - 124px), calc(100vh - 124px));
  }
  &.has-notifications {
    grid-template-rows: 112px Max(calc(100% - 112px), calc(100vh - 112px));
  }
  &.impersonate.has-notifications {
    grid-template-rows: 172px Max(calc(100% - 172px), calc(100vh - 172px));
  }

  header {
    grid-area: header;
  }
  .navigation {
    grid-area: navigation;
  }

  /* Collapsed */
  &.collapsed {
    grid-template-columns: 78px calc(100% - 78px);
  }

  &.no-nav {
    grid-template-columns: 1fr;
  }

  &.not-completed {
    grid-template-columns: 1fr;
    .navigation {
      display: none;
    }
  }

}

/* flex Layout */
.layout-flex {
  display: flex;
}

.main-container {
  margin: 0 auto;
  max-width: 1366px;
  min-width: 1336px;
}

.primary-link {
  color: $primay;
  font-size: 12px;
  line-height: 16px;
  border: none;
}

.filter-button,
.add-button,
.edit-button,
.delete-button,
.column-settings-button {
  align-items: center;
  display: flex;
  i {
    background: url("../images/icon-filter.svg") no-repeat center center;
    display: block;
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }
}

.add-button {
  i {
    background-image: url("../images/icon-add-white.svg");
  }
  &:disabled {
    i {
      background-image: url('../images/icon-add-black.svg');
      opacity: .6;
    }
  }
}

.edit-button {
  i {
    background-image: url("../images/icon-edit.svg");
  }
}

.delete-button {
  i {
    background-image: url("../images/icon-delete.svg");
  }
}

.column-settings-button {
  i {
    background-image: url("../images/icon-settings.svg");
  }
}

// Table style
.table-layout {
  table {
    border: $border solid 1px;
    border-collapse: collapse;
    margin-bottom: 0;
    width: 100%;
    th {
      border-bottom: $border solid 1px;
      color: $default;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 12px 10px 12px;
      text-align: left;
      white-space: nowrap;

      &.text-align-right {
        text-align: right;
      }

      &:last-child {
        padding-right: 12px;
      }
    }
    td {
      border-bottom: $border solid 1px;
      color: $default;
      font-size: 14px;
      padding: 12px 12px 12px 12px;
      
      &.text-align-right {
        text-align: right;
      }

      &:last-child {
        padding-right: 12px;
      }
    }
    tr {
      &.highlight {
        td {
          background-color: $active;
        }
      }
    }
    // Sort icon
    .sort {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      &:after {
        content: "";
        display: block;
        height: 10px;
        margin-left: 10px;
        width: 10px;
      }
      &.asc {
        &::after {
          background: url("../images/icon-sort.svg") no-repeat center center/
            10px 10px;
        }
      }
      &.desc {
        &::after {
          background: url("../images/icon-sort.svg") no-repeat center center/
            10px 10px;
          transform: rotate(180deg);
        }
      }
    }
    .operate {
      display: flex;
    }
    .edit {
      background: url("../images/icon-edit.svg") no-repeat center center/ 16px
        16px;
      display: inline-block;
      height: 16px;
      margin-right: 20px;
      width: 16px;
      margin-left: 0;
      padding: 0;

      &:disabled {
        opacity: 0.6;
      }
    }
    .download {
      background: url("../images/icon-download.svg") no-repeat center center/
        16px 16px;
      display: inline-block;
      height: 16px;
      margin-right: 20px;
      width: 16px;
      border: none;
      padding: 0;
      margin-left: 0;

      &:disabled {
        opacity: 0.6;
      }
    }
    .view {
      background: url("../images/icon-eye-grayScaleDark-18x18.svg") no-repeat center center/
      18px 18px;
      display: inline-block;
      height: 18px;
      margin-right: 20px;
      width: 18px;
      border: none;
      padding: 0;
      margin-left: 0;

      &:disabled {
        opacity: 0.6;
      }
    }
    .delete {
      background: url("../images/icon-remove.svg") no-repeat center center/ 16px
        16px;
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-left: 0;
      padding: 0;

      &:disabled {
        opacity: 0.6;
      }
    }
    .impersonate {
      background: url("../images/icon-impersonate.svg") no-repeat center center/ 16px
        16px;
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-left: 20px;
      padding: 0;

      &:disabled {
        opacity: 0.6;
      }
    }
  }
  .status {
    border-radius: $radius;
    display: inline-block;
    font-weight: 600;
    line-height: 23px;
    padding: 0 8px;
    text-align: center;
    &.active {
      background-color: $active-background;
      color: $blank;
    }
    &.error {
      background-color: $error-border;
      color: $blank;
    }
  }
  .badge-status {
    align-items: center;
    border-radius: $radius;
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 0 8px;
    white-space: nowrap;
    &.success,
    &.successful,
    &.filed,
    &.mapped,
    &.reviewed,
    &.active {
      background-color: $success;
      color: $blank;
      &::before {
        background: url("../images/icon-check.svg") no-repeat center center/16px
          16px;
        content: "";
        display: block;
        height: 16px;
        margin-right: 6px;
        width: 16px;
      }
    }
    &.error,
    &.cancelled,
    &.system-cancelled,
    &.file-rejected,
    &.inactive {
      background-color: $error;
      color: $blank;
      &::before {
        background: url("../images/icon-x-circle.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
    &.missing-assessor-account {
      background-color: $error;
      color: $blank;
      &::before {
        background: url("../images/icon-x-circle.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
    &.required {
      background-color: $error;
      color: $blank;
      &::before {
        background: url("../images/icon-infor-white.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
    &.review-required,
    &.mapping-needed {
      @extend .required;
      &::before {
        background: url("../images/icon-alert-triangle-white.svg") no-repeat
          center/14px 16px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 16px;
      }
    }
    &.error-required {
      &::before {
        background: url("../images/icon-infor-white.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
    &.warning,
    &.action-required,
    &.duplicate,
    &.review-recommended,
    &.review-assets,
    &.review-for-approval,
    &.ready-to-map {
      background-color: $warning;
      &::before {
        background: url("../images/icon-alert-triangle.svg") no-repeat center
          center/14px 16px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 16px;
      }
    }
    &.duplicate {
      &::before {
        background: url("../images/icon-duplicate.svg") no-repeat center
          center/12px 12px;
        height: 12px;
        width: 12px;
      }
    }
    &.normal {
      background-color: $normal;
      &::before {
        background: url("../images/icon-info-black.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
    &.default,
    &.unused,
    &.unmapped {
      background-color: $block;
      &::before {
        background: url("../images/icon-help-circle.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
    &.ready,
    &.approved {
      background-color: $ready;
      &::before {
        background: url("../images/icon-check-circle.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
    &.in-progress,
    &.scanning-file {
      background-color: $ready;
      &::before {
        background: url("../images/icon-in-progress.svg") no-repeat center
          center/14px 14px;
        content: "";
        display: block;
        height: 14px;
        margin-right: 6px;
        width: 14px;
      }
    }
  }
}

/* Progress bar */
.progress-bar {
  background-color: $block;
  border-radius: 5px;
  height: 10px;
  width: 100%;
  .inner {
    background-color: $primay;
    border-radius: 5px;
    height: 10px;
    width: 0;
  }
}

/* Remove icon */
.icon-remove {
  background: url("../images/icon-close.svg") no-repeat center center;
  display: block;
  flex-shrink: 0;
  height: 12px;
  width: 12px;
}

/* Pagination */
.pagination {
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0 12px 0;
  .per-page {
    align-items: center;
    display: flex;
    margin-right: 24px;
    .FieldSelect {
      margin-left: 14px;
    }
  }
  .form-control {
    margin-left: 18px;
    width: 80px;
  }
  .pager-input {
    align-items: center;
    display: flex;
    margin-right: 32px;
    input {
      border: $border solid 1px;
      border-radius: $radius;
      height: 32px;
      margin: 0 14px;
      text-align: center;
      width: 35px;
    }
  }
  .details {
    margin-right: 34px;
  }
  .pager {
    align-items: center;
    display: flex;
    button {
      border-width: 0;
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 12px 12px;
      display: block;
      height: 24px;
      margin: 0 10px;
      width: 24px;
      &.first {
        background-image: url("../images/icon-first.svg");
      }
      &.prev {
        background-image: url("../images/icon-prev.svg");
      }
      &.next {
        background-image: url("../images/icon-prev.svg");
        transform: rotate(180deg);
      }
      &.last {
        background-image: url("../images/icon-first.svg");
        transform: rotate(180deg);
      }
      /* Disabled */
      &.disabled {
        cursor: default;
        opacity: 1;
        &.first {
          background-image: url("../images/icon-first-disabled.svg");
        }
        &.prev {
          background-image: url("../images/icon-prev-disabled.svg");
        }
        &.next {
          background-image: url("../images/icon-prev-disabled.svg");
        }
        &.last {
          background-image: url("../images/icon-first-disabled.svg");
        }
      }
    }
  }
}

/* Grid layout similar table */
.table-grid {
  align-items: center;
  border: $border solid 1px;
  border-collapse: collapse;
  display: grid;
  grid-template-columns: 140px 200px repeat(3, 1fr) 80px;
  grid-template-rows: 42px auto;
  .table-group {
    border-top: $border solid 1px;
    grid-column: 1/10;
    .icon-accorid {
      background: url("../images/icon-accordion-table.svg") no-repeat center
        center;
      display: block;
      height: 16px;
      width: 16px;
    }
    .table-children {
      display: none;
    }
    &.expanded {
      & > .table-row {
        & > .table-column {
          .icon-accorid {
            transform: rotate(-90deg);
          }
        }
        & + .table-children {
          display: block;
        }
      }
    }
  }
  .table-row {
    display: grid;
    grid-template-columns: 140px 200px repeat(3, 1fr) 80px;
    grid-template-rows: auto auto;
  }
  .table-column,
  .table-header {
    font-size: 14px;
    line-height: 18px;
    padding: 0 6px;
  }
  .table-header {
    align-items: center;
    display: flex;
    font-weight: 600;
  }
  .table-column {
    padding: 12px 6px;
    &:last-child {
      align-self: center;
    }
    p {
      color: $header;
      font-size: 12px;
      padding-top: 4px;
    }
  }
  // Sort icon
  .sort {
    align-items: center;
    cursor: pointer;
    display: flex;
    &:after {
      content: "";
      display: block;
      height: 10px;
      margin-left: 10px;
      width: 10px;
    }
    &.asc {
      &::after {
        background: url("../images/icon-sort.svg") no-repeat center center/ 10px
          10px;
      }
    }
    &.desc {
      &::after {
        background: url("../images/icon-sort.svg") no-repeat center center/ 10px
          10px;
        transform: rotate(180deg);
      }
    }
  }
  .operate {
    align-items: center;
    display: flex;
  }
  .edit {
    background: url("../images/icon-edit.svg") no-repeat center center/ 16px
      16px;
    display: block;
    height: 16px;
    margin-right: 20px;
    width: 16px;
    margin-left: 0;
    padding: 0;

    &:disabled {
      opacity: 0.6;
    }
  }
  .download {
    background: url("../images/icon-download.svg") no-repeat center center/ 16px
      16px;
    display: block;
    height: 16px;
    margin-right: 20px;
    width: 16px;
    border: none;
    padding: 0;

    &:disabled {
      opacity: 0.6;
    }
  }
  .delete {
    background: url("../images/icon-remove.svg") no-repeat center center/ 16px
      16px;
    display: block;
    height: 16px;
    margin-right: 20px;
    width: 16px;
    margin-left: 0;
    padding: 0;

    &:disabled {
      opacity: 0.6;
    }
  }
  .liner {
    padding: 0 4px;
  }
}

.mappedfile-result {
  ul {
    justify-content: space-between;
    display: flex;
    padding-bottom: 8px;
  }
  li {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    margin-right: 36px;
  }
  span.block {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
  }
  p {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    .icon {
      background: url("../images/icon-xlsx.svg") no-repeat center center;
      display: block;
      flex-shrink: 0;
      height: 20px;
      margin-right: 12px;
      width: 20px;
    }
  }
  span {
    color: $header;
    display: block;
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    padding-bottom: 8px;
    text-transform: uppercase;
  }
  span[data-tip='React-tooltip'] {
    display: inline-block;
  }
  .primary-link {
    padding: 0;
    height: auto;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* Modal */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba($color: $black, $alpha: 0.5);
}

.modal-body {
  p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}

.loader-container {
  background-color: $modal-mask;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  .loader {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.selectAssetType {
  width: 400px;
  h4 {
    font-size: 14px;
    font-weight: 600;
  }
}

.flyout-panel {
  background-color: $blank;
  box-shadow: $shadow -2px 0 10px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  min-width: 270px;
  overflow: auto;

  > div:first-of-type {
    height: 100%;
  }
}

.settings {
  .header {
    align-items: center;
    border-bottom: $block solid 1px;
    justify-content: space-between;
    display: flex;
    margin: 0 20px 5px 20px;
    padding: 16px 0;
    h3 {
      align-items: center;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      &::before {
        background: url("../images/icon-column-settings.svg") no-repeat center
          center/ 24px 24px;
        content: "";
        display: block;
        height: 24px;
        margin-right: 12px;
        width: 24px;
      }
    }
    .arrow {
      align-items: center;
      border-left: $border solid 1px;
      display: flex;
      height: 24px;
      justify-content: flex-end;
      flex-shrink: 0;
      width: 20px;
      &::before {
        background: url("../images/arrow-right.svg") no-repeat center center/8px
          12px;
        content: "";
        display: block;
        height: 12px;
        width: 8px;
      }
    }
  }
  .body {
    margin: 0 20px;
    li {
      border-bottom: $block solid 1px;
      padding: 10px 0;
    }
  }
  .footer {
    padding: 24px 20px 36px 20px;
  }
}

.react-tags-wrapper {
  .ReactTags__selected {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
}

.tag-wrapper {
  border: #dddddd solid 1px;
  border-radius: 8px;
  padding: 2px;
  display: flex;
  align-items: center;
  background-color: $normal;
  button {
    border: none;
    background-color: $normal;
    margin: 2px;
  }
}

hr {
  margin: 0;
  border-top: 1px solid $border;
  border-bottom: none;
}

.padding-0 {
  padding: 0 !important;
}

// Do not show react-pdf annotation, it may cause large empty space below
// https://gitlab.com/avalara-dev/avalara-frontend/-/issues/234
.react-pdf__Document .annotationLayer .linkAnnotation {
  display: none;
}

// Adjust the CKEditor css
.ck.ck-content {
  height: 300px;
  background-color: transparent;
  border-width: 0;
  color: $default;
  font-size: 14px;
  width: 100%;
  resize: none;
  padding: 8px;

  p {
    text-align: left;
  }
}

.ck.ck-toolbar {
  .ck-dropdown,
  .ck-button {
    margin-right: 0 !important;
  }
  .ck-toolbar__separator {
    margin-left: 12px;
    margin-right: 12px !important;
  }
  .ck-button_with-text {
    padding-left: 0px;
    padding-right: 0px;
  }
}

body {
  --ck-z-default: 100;
  --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

// Move selector dropdown lower 2px
// https://gitlab.com/avalara-dev/avalara-frontend/-/issues/274
s-select div[data-ref="select-ul-container"] {
  top: calc(100% + 1px);
}


s-loader.button-loading {
  width: 17px;
  height: 17px;
  margin-top: 3px;
  margin-bottom: -3px;
  margin-left: 5px;

  &.icon-button {
    margin-left: 0;
  }
}

s-popper[type='tooltip'] {
  z-index: 9999;
}

s-dropdown[open] > [slot="dropdown-content"] {
  z-index: unset;
}

s-column-manager {
  ul,
  [slot="sticky-footer"] {
    z-index: 1000;
  }
}

s-select {
  ul,
  div[data-ref="search-input-div"] {
    z-index: 101;
  }
}

.impersonate-modal {
  width: 338px;
  button[aria-label='Close'] {
    display: none;
  }
  .modal-body {
    p {
      padding: 0 12px;
    }
  }
}

s-datepicker div[data-ref='header'] {
    button.small {
      padding-left: 0;
      padding-right: 0;
    }
}

s-datepicker,
s-monthpicker,
x-monthpicker {
  [data-ref="picker"] {
    z-index: 101;
  }
}

s-popper.datepicker[data-ref="picker"] {
  z-index: 1001;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}